const hamburger = document.querySelector('.hamburger');
const nav = document.querySelector('.navigation__list');
const pageBody = document.querySelector('body');
const submenu = document.querySelector('.submenu');
const submenuTrigger = document.querySelector('.navigation__list__submenu')
// const arrow = submenuTrigger.querySelector('span')
hamburger.addEventListener('click', () => {
    nav.classList.toggle('navigation__list--active');
    hamburger.classList.toggle('active');
    if (nav.classList.contains('navigation__list--active')) {
        pageBody.style.overflowY = 'hidden';
    }
    else {
        pageBody.style.overflowY = 'auto';

    }
})

// // submenuTrigger.addEventListener('click', () => {
// //     submenu.classList.toggle('submenu__active');
// //     // arrow.classList.toggle('navigation__list__submenu__span--active');

// // })

// document.addEventListener('click', (event) => {
//     const isClickInsideSubmenuTrigger = submenuTrigger.contains(event.target);
//     if (!isClickInsideSubmenuTrigger && submenu.classList.contains('submenu__active')) {
//         submenu.classList.remove('submenu__active');
//         submenuTrigger.classList.remove('navigation__list__submenu--active');

//     }
// });

const calculateHeaderHeight = () => {
    const header = document.querySelector('.main-header');

    const runCalculation = () => {
        if (header) {
            const headerHeight = header.offsetHeight;
            document.documentElement.style.setProperty('--header-height', `${headerHeight + 50}px`);
        }
    };

    window.addEventListener('resize', runCalculation);
    runCalculation();
};


document.addEventListener('DOMContentLoaded', () => {

    calculateHeaderHeight();
});